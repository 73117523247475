.nav-tabs {
	border-bottom: 1px solid rgba($pale-blue, 0.3);
	.nav-item {
		display: inline-block;
		padding: 5px 15px 0px 15px;
		margin: 0 10px;
		@include dinpro-medium();
		@include fontSize(15px);
		&:hover {
			.nav-link {
				color: rgba($blue, 0.5);
				&.active {
					color: $blue;
				}
			}
		}
		.nav-link {
			padding: 0 0 5px 0;
			text-decoration: none;
			border: 0;
			color: $blue;
			&.active {
				border-bottom: 6px solid $blue;
				background-color: transparent;
			}
			h2 {
				margin-bottom: 0;
				line-height: inherit;
				@include fontSize(15px);
			}
		}
	}
	&.nav-tabs-secondary {
		flex-direction: column;
		.nav-item {
			display: block!important;
			padding: 0px;
			margin: 0px;
			border-radius: 0;
			border-bottom: 1px solid rgba($pale-blue, 0.3);
			@include nimbus(700);
			@include fontSize(14px);
			&:last-child {
				border-bottom: 0;
			}
			.nav-link {
				position: relative;
				padding: 10px 15px 10px 45px;
				text-decoration: none;
				border: 0;
				color: $blue;
				border-radius: 0;
				@include transition(all .4s ease-in-out);
				&:before {
					content: "\e901";
					font-family: "icomoon";
					color: $blue;
					position: absolute;
					top: calc(50% - 11px);
					left: 15px;
					@include fontSize(15px);
					@include transition(all .4s ease-in-out);
				}
				&:hover, &.active {
					padding: 10px 15px 10px 65px;
					border-bottom: 0;
					color: $jade;
					background-color: rgba($pale-blue, 0.1);
					@include transition(all .4s ease-in-out);
					&:before {
						left: 35px;
						color: $jade;
						@include transition(all .4s ease-in-out);
					}
					@include media-breakpoint-down(sm){
						padding: 10px 15px 10px 45px;
						background: 0;
						color: $blue;
						&:before {
							left: 15px;
							color: $blue;
						}
					}
				}
			}
		}
	}
}

.tab-content {
	.tab-pane {
		&.active {}
	}
}
