.home {
	.home-header {
		height: calc(100vh + 108px);
		background-color: $blue;
		&.anim {
			height: calc(100vh - 108px);
		}
		@include media-breakpoint-down(md){
			height: inherit;
			padding-top: 90px;
			padding-bottom: 50px;
			.alert-scroll {
				display: none;
			}

			&.anim {
				height: inherit!important;
			}
		}
		@include media-breakpoint-down(sm){
			padding-top: 50px;
		}
		h1 {
			display: inline;
			@include fontSize(45px);
			@include media-breakpoint-down(sm){
				@include fontSize(27px);
			}
		}
		.insurance-values {
			color: $midnight-blue;
			@include fontSize(39px);
			@include media-breakpoint-down(sm){
				@include fontSize(27px);
			}
			@include dinpro-bold();
		}
		.description-intro {
			line-height: 30px;
			@include fontSize(19px);
			@include nimbus(400);
		}
		.animContainer {
			position: relative;
			transition: all 0.25s;
			@include make-row();
			&:before,
			&:after {
				content: '';
				position: absolute;
				// Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts
				border: 5px solid transparent;
				width: 0;
				height: 0;
				z-index: 999;
			}
			// This covers the top & right borders (expands right, then down)
			&:before {
				bottom: 0;
				right: 0;
			}
			// And this the bottom & left borders (expands left, then up)
			&:after {
				top: 0;
				left: 0;
			}
			&.border-draw {
				color: $jade;
				&:before,
				&:after {
					width: 100%;
					height: 100%;
				}
				&:before {
					border-bottom-color: $jade; // Make borders visible
					border-left-color: $jade;
					transition:
						width 0.25s ease-out, // Width expands first
						height 0.25s ease-out 0.25s; // And then height
				}
				&:after {
					border-top-color: $jade; // Make borders visible
					border-right-color: $jade;
					transition:
						border-color 0s ease-out 0.5s, // Wait for ::before to finish before showing border
						width 0.25s ease-out 0.5s, // And then exanding width
						height 0.25s ease-out 0.75s; // And finally height
				}
			}
			.small-square {
				visibility: hidden;
				padding: 5px!important;
				width: 25%;
				&:after {
					content: "";
					display: block;
					padding-bottom: 100%;
					border: 1px solid $white;
				}
			}
			.image {
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				@include transition(all 1.5s ease-in-out -.1s);
				&.opacity {
					opacity: 1;
					@include transition(all 1.5s ease-in-out -.1s);
				}
			}
		}
	}
	.home-company {
		h2 {
			@include fontSize(30px);
			@include media-breakpoint-down(sm){
				@include fontSize(22px);
			}
		}
		.list-staff {
			margin-top: 100px;
		}
	}
	.home-news {
		h2 {
			@include fontSize(30px);
			@include media-breakpoint-down(sm){
				@include fontSize(22px);
			}
		}
		ul {
			li {
				@include media-breakpoint-down(lg) {
					&:last-child {
						display: none;
					}
				}
			}
		}
	}
}