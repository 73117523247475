// ================================================
// 					FOOTER
// ================================================
.main-footer {
	.pre-footer {
		padding: 40px 0;
		@include fontSize(15px);
		h3 {
			max-width: 400px;
		}
		.btn.btn-jade-inverted {
			&:hover, &:focus {
				border: 1px solid $jade;
			}
		}
	}
	.main-content-footer {
		padding: 40px 0;
		background-color: $blue;
		color: $white;
		@include fontSize(12px);
		.form-newsletter {
			.btn {
				width: 100%;
				padding: 10px 15px;
			}
		}
		.logo {
			display: block;
		}
		.menu-footer {
			li {
				display: inline-block;
				margin: 0 25px;
				@include media-breakpoint-down(xs){
					display: block;
					margin: 5px 0;
				}
				&:first-child {
					margin-left: 0;
				}
				a {
					color: $white;
					@include fontSize(15px);
				}
			}
		}
		.menu-terms {
			li {
				display: inline-block;
				margin-right: 15px;
				@include media-breakpoint-down(xs){
					display: block;
					&:before {
						display: none;
					}
				}
				&:before {
					content: '|';
					color: $white;
					margin-right: 15px;
					@include fontSize(14px);
				}
				&:first-child {
					&:before {
						display: none;
					}
				}
				a {
					color: $white;
					@include fontSize(14px);
				}
			}
		}
		a {
			&:hover {
				color: $white;
			}
			&:focus {
				color: $white;
				text-decoration: underline;
			}
		}
		.secondary-footer {
			padding-top: 5px;
			border-top: 1px solid rgba($white, .3);
		}
	}
}