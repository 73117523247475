// ================================================
// 							PROJECT VARIABLES
// ================================================
// COLORS

$white				: #FFF;
$black				: #000;
$selection			: rgba(#00B666, 0.1);

// PRIMARY-COLORS
$blue				: #0057A7;
$jade				: #00B666;

// SECONDARY-COLORS
$midnight-blue		: #021F5C;
$pale-blue			: #667084;
$grey				: #3C3C3B;
$grey-light			: #D7D7D7;

$red				: #D0021B;
$green				: #5FBD3E;

.white {
	color: $white;
}
.grey {
	color: $grey;
}
.jade {
	color: $jade;
}
.blue {
	color: $blue;
}
.pale-blue {
	color: $pale-blue;
}
.midnight-blue {
	color: $midnight-blue;
}

// BACKGROUND-COLORS

// ================================================
//							 AUXILIAR VARIABLES
// ================================================

// SOCIAL MEDIA COLORS
$tw					: #55ACEE;  // TWITTER
$fb					: #3B5998;  // FACEBOOK
$gp					: #DD4B39;  // GOOGLE+
$yt					: #FF3333;  // YOUTUBE
$pin				: #CB2027;  // PINTEREST
$ig					: #517fa4;  // INSTAGRAM
$li					: #007bb6;  // LINKEDIN