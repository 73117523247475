.post {
	.post-body {
		pre, p, ul li, ol li {
			a {
				color: $jade;
				font-weight: 700;
				background: linear-gradient(to bottom, transparent 62%, rgba($jade, 0.3) 0) center center/0% 75% no-repeat;
				@include transition(all .4s ease-in-out);
				&:hover, &:focus {
					background-size: 100% 100%;
					text-decoration: none;
					@include transition(all .4s ease-in-out);
				}
				&:visited {
					background-size: 80% 100%;
					@include transition(all .4s ease-in-out);
				}
			}
		}
		ul {
			margin: 25px 0;
			list-style-type: none;
			li {
				margin: 4px 0 8px 0;
				&:before {
					content: "\2022";
					color: $blue;
					margin-right: 7px;
				}
			}
		}
		ol {
			margin: 25px 0;
			list-style-type: none;
			counter-reset: li;
			padding-left: 0;
			li {
				color: $blue;
				margin: 4px 0 8px 0;
				&:before {
					counter-increment: li;
					content: counters(li,".") ".";
					font-weight: 700;
					margin-right: 7px;
					@include fontSize(16px);
				}
			}
		}
		p {
			img {
				margin: 15px auto!important;
			}
			img.aligncenter {
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
			iframe {
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
		}
		.wp-caption {
			margin: 15px auto!important;
		}
		.wp-caption-text {
			text-align: right;
			color: $pale-blue;
			margin: 5px 0;
			@include fontSize(15px);
		}
		.gallery {
			text-align: center;
			a {
				display: block;
				&:before {
					display: none;
				}
			}
		}
		.gallery-item {
			display: inline-block;
			margin: 10px;
			a {
				img {
					margin: 0;
				}
			}
		}
	}

	.post-footer {}

	&.single, &.page {
		h1 {
			@include media-breakpoint-down(md){
				line-height: 31px;
				@include fontSize(30px);
			}
			@include media-breakpoint-down(sm){
				@include fontSize(28px);
			}
		}
		progress {
			position: absolute;
			width: 100%;
			height: 5px;
			left: 0;
			-webkit-appearance: none;
			-moz-appearance: none;
					appearance: none;
			/* Get rid of the default border in Firefox/Opera. */
			border: none;

			/* Progress bar container for Firefox/IE10+ */
			background-color: $grey-light;

			/* Progress bar value for IE10+ */
			color: $jade;
			&.fixed {
				position: fixed;
				width: calc(100% - 10px);
				top: 5px;
				left: 5px;
				z-index: 1;
			}
		}
		progress[value]::-webkit-progress-bar {
			background-color: $grey-light;
		}
		progress::-webkit-progress-value {
			background-color: $jade;
		}
		.single-item-featured, .page-item-featured {
			position: relative;
			.single-item-date {
				position: absolute;
				width: 95px;
				height: 95px;
				bottom: 0;
				left: calc(50% - 47.5px);
				padding: 15px;
				background-color: $white;
				color: rgba($pale-blue, 0.7);
				@include fontSize(25px);
				@include dinpro-medium();
				@include transition(all 0.3s ease-in-out);
				@include media-breakpoint-down(md){
					width: 70px;
					height: 70px;
					left: calc(50% - 35px);
					padding: 10px;
					@include fontSize(20px);
				}
				span {
					display: block;
					@include fontSize(15px);
					@include dinpro();
					@include media-breakpoint-down(md){
						@include fontSize(14px);
					}
				}
			}
		}
		.post-body {
			.excerpt {
				line-height: 34.5px;
				@include fontSize(23px);
				@include dinpro();
				@include media-breakpoint-down(md){
					line-height: 28px;
					@include fontSize(20px);
				}
			}
			.subtitle {
				margin: 50px 0 30px 0;
			}
		}
	}
	&.single-main-cat {
		.post-body {
			.main-content-title {
				@include dinpro();
			}
		}
	}
	&.single-subcategory {
		.post-header {
			.container {
				position: relative;
				z-index: 0;
				&:after {
					content:"";
					width: 400px;
					height: 400px;
					border: 4px solid $jade;
					background-color: transparent;
					position: absolute;
					right: 10%;
					bottom: -20%;
					z-index: -1;
					@include media-breakpoint-down(lg){
						width: 300px;
						height: 300px;
						right: 0;
					}
					@include media-breakpoint-down(md){
						width: 250px;
						height: 250px;
						right: 15px;
					}
					@include media-breakpoint-down(xs){
						width: 200px;
						height: 200px;
						right: 15px;
					}
				}
			}
			h1 {
				line-height: 27px;
				@include fontSize(21px);
			}
			h2 {
				line-height: 52px;
				@include fontSize(36px);
				@include media-breakpoint-down(md){
					line-height: 32px;
					@include fontSize(28px);
				}
			}
			h3 {
				line-height: 25px;
				@include fontSize(19px);
				@include dinpro();
			}
		}
	}
	&.single-product {
		.post-header {
			h1 {
				line-height: 27px;
				@include fontSize(21px);
			}
			.item-excerpt {
				color: $white!important;
			}
		}
		.post-body {
			.image-insurance-company {
				img {
					width: 150px;
				}
			}
			.sidebar {
				&.fixed {
					position: fixed;
					//width: 100%;
					top: 96px;
					@include transition(all .5s ease-in-out);
				}
				&.bottom {
					position: absolute;
					bottom: 0;
					top: auto;
					@include transition(all .5s ease-in-out);
				}
			}
		}
	}
	&.single-insurance-company {
		.post-header {
			h1 {
				margin-top: 100px;
				@include fontSize(36px);
			}
			.insurance-logo {
				width: 150px;
				height: 60px;
				margin: 30px auto 0 auto;
				position: relative;
				background-size: contain;
				&:before {
					content: '';
					position: absolute;
					background-color: $white;
					width: 200px;
					height: 70px;
					left: -25px;
					top: -5px;
					z-index: -1;
				}
			}
			.swiper-container {
				padding: 0 22px;
				margin-top: -110px;
				@media screen and(max-width: 1024px){
					//padding: 0px;
				}
				.swiper-wrapper {
					//margin-left: 20px;
				}
				.item-product {
					h3 {
						margin-top: 15px;
						@include fontSize(22px);
					}
					.item-excerpt {
						@include fontSize(14px);
					}
				}
			}
			.swiper-custom-nav {
				position: absolute;
				width: 105%;
				top: -40px;
				left: -2.5%;
				@media screen and(max-width: 1024px){
					width: 110%;
					left: -5%;
				}
				a {
					padding: 5px;
					&:hover, &:focus {
						color: rgba($white, .8);
					}
				}
			}
		}
		.post-body {
			margin-top: 220px;
		}
	}
	&.page-news {
		.post-body {
			h1 {
				text-transform: uppercase;
				@include dinpro();
				@include fontSize(21px);
			}
		}
	}
	&.page-faq {
	}
	&.page-docs {
	}
	&.single-subcategory, &.single-product, &.single-insurance-company, &.page-news, &.single, &.page {
		.post-header {
			height: 420px;
			position: relative;
			@include media-breakpoint-down(md){
				height: 320px;
			}
			.post-header-image, .news-item-image, .single-item-image, .page-item-image {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				figure {
					background-attachment: fixed;
					@include media-breakpoint-down(md){
						background-attachment: initial;
					}
					&:after {
						content:"";
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: rgba($grey, .4);
					}
				}
			}
		}
	}
	&.single-product, &.single-insurance-company {
		.post-header {
			.post-header-image {
				overflow: hidden;
				figure {
					position: absolute;
					top: -1%;
					left: -1%;
					width: 102%;
					height: 102%;
					-webkit-filter: blur(6px);
					-moz-filter: blur(6px);
					-o-filter: blur(6px);
					-ms-filter: blur(6px);
					filter: blur(6px);
				}
			}
		}
	}
	&.single-main-cat, &.page-faq, &.page-about, &.page-docs, &.page-contacts {
		.post-header {
			.subtitle {
				line-height: 27px;
				@include nimbus();
				@include fontSize(19px);
			}
		}
	}
}
.page-search {
	h1 {
		@include fontSize(15px);
		@include nimbus();
		span {
			@include nimbus(700);
		}
	}
	@include media-breakpoint-down(sm){
		.nav-tabs {
			.nav-item {
				padding: 0;
				@include fontSize(13px);
			}
		}
	}
}