 .contact-form-container {
	border-left: 5px solid $blue;
	border-right: 5px solid $blue;
	.logo {
		width: 150px;
	}
	.contact-form-header {
		border-bottom: 1px solid rgba($pale-blue, .3);
	}
	.contact-form {
		label[for="checkPrivacy"] {
			@include nimbus();
			@include fontSize(12px);
		}
	}
 }
.form-group {
	@include fontSize(13px);
	label {
		cursor: pointer;
	}
	input, select, textarea {
		padding: 10px 15px;
		background-color: $white;
		border-radius: 1px;
		border: 1px solid rgba($grey, 0.4);
		@include fontSize(13px);
		&:focus {
			border: 1px solid $blue;
			box-shadow: none;
			outline: none;
		}
		&.success {
			border: 1px solid $green;
		}
		&.error {
			color: $red;
			border: 1px solid $red;
		}
		&.disabled {
			background-color:rgba($grey-light, 0.1);
		}
		&.secondary {
			background-color: transparent;
			border-radius: 1px;
			border: 1px solid $white;
			color: $white;
			-webkit-text-fill-color: $white;
		}
	}
	input[type="search"] {
		@include fontSize(19px);
		&:focus {
			color: $blue;
		}
	}
	/* Change autocomplete styles in WebKit */
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus
	input:-webkit-autofill,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		-webkit-text-fill-color: $grey;
		-webkit-box-shadow: 0 0 0px 1000px rgba($jade, 0.2) inset;
		transition: background-color 5000s ease-in-out 0s;
	}
	textarea {
		height: 80px;
		resize: none;
	}
}

.form-newsletter {
	.btn {
		margin-left: -1px;
		border-color: $white;
		display: block;
		min-width: auto;
		padding: 0.667em 0;
		@include media-breakpoint-down(sm){
			@include fontSize(14px);
		}
		&:hover, &:focus {
			border-color: $white;
		}
	}
}

// ========================================
//                CHECKBOXES
// ========================================

input[type="checkbox"],
input[type="radio"] {
	position: absolute;
	opacity: 0;
	z-index: 1;
	& + label {
		display: inline-block;
		position: relative;
		color: $pale-blue;
		font-weight: 700;
		@include fontSize(14px);
		@include transition(color 0.3s ease-in-out);
		&:before {
			content: "";
			width: 16px;
			height: 16px;
			vertical-align: middle;
			display: inline-block;
			position: relative;
		    top: -2px;
			background-color: $white;
			border: solid 1px $blue;
			border-radius: 2px;
			margin-right: 10px;
			@include transition(border-color 0.3s ease-in-out);
		}
	}
	&:hover + label, &:focus + label{
		color: $pale-blue;
		@include transition(color 0.3s ease-in-out);
		&:before {
			border-color: $pale-blue;
			@include transition(border-color 0.3s ease-in-out);
		}
	}
	&:checked {
		& + label {
			color:$pale-blue;
			&:before {
				content:"\e902";
				font-family: 'icomoon';
				color: $white;
				background-color: $blue;
				line-height: 16px;
				padding-left: 3px;
				@include fontSize(8px);
			}
		}
	}
	&[disabled],
	&[readonly] {
		& + label {
			cursor: no-drop!important;
			&:before {
				content:"\e902";
				font-family: 'icomoon';
				color: $white;
				border-color: $grey-light;
				background-color: $grey-light;
				line-height: 16px;
				padding-left: 3px;
				@include fontSize(8px);
			}
		}
	}
	&.checkbox-secondary {
		& + label {
			color: $white;
			&:before {
				background-color: $grey-light;
				border: solid 1px $grey-light;
			}
		}
		&:hover + label, &:focus + label{
			color: $white;
			&:before {
				border-color: $grey-light;
			}
		}
		&:checked {
			& + label {
				color: $white;
				&:before {
					color: $blue;
					background-color: $grey-light;
				}
			}
		}
	}
}
// ========================================
//             RADIO BUTTONS
// ========================================

input[type="radio"] {
	position: absolute;
	opacity: 0;
	& + label {
		&:before {
			border-radius: 9px;
		}
	}
	&:checked {
		& + label {
			color:$pale-blue;
			&:before {
				content: '●';
				color:$blue;
				border-color:$blue;
				background-color: $white;
				line-height: 11px;
				padding-left: 2.8px;
				@include fontSize(16px);
			}
		}
	}
	&[disabled],
	&[readonly] {
		& + label {
			cursor: no-drop!important;
			&:before {
				content: '●';
				color:$white;
				border-color: $grey-light;
				background-color: $grey-light;
				line-height: 11px;
				padding-left: 2.8px;
				@include fontSize(16px);
			}
		}
	}
}
// ========================================
//             Dropdowns com inputs
// ========================================


.dropdown-filters-container {
	position: relative;
	.dropdown-toggler{
		display: block;
		border-radius: 1px 1px 0 0;
		background-color: $blue;
		padding: 10px 15px;
		color: $white;
		font-weight: 700;
		position: relative;
		text-decoration: none;
		@include fontSize(14px);
		&:after{
			content: "\e905";
			font-family: 'icomoon';
			position: absolute;
			top: calc(50% - 4px);
			right: 15px;
			@include fontSize(8px);
		}
		&.collapsed{
			&:after{
				content: "\e904";
			}
		}
		&:focus {
			color: $white!important;
		}

	}
	.dropdown-filters-list {
		border-radius: 0 0 1px 1px;
		position: absolute;
		width: 100%;
		//max-height: 210px;
		//overflow-y: auto;
		left: 0;
		top: 100%;
		z-index: 100;
		padding: 15px 20px;
		background-color: $blue;
		margin: 0!important;
		li {
			background-color: $blue;
			margin: 5px 0;
			padding: 0;
			word-wrap: break-word;
			-webkit-touch-callout: none;
			color: $white;
			label {
				margin-bottom: 0;
			}
		}
	}
	/*::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba($white,0.2);
		border-radius: 10px;
		background-color: rgba($white,0.3);
	}

	::-webkit-scrollbar {
		width: 7px;
		border-radius: 10px;
		background-color: rgba($white,0.3);
	}

	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba($white,.2);
		background-color: $white;
	}*/
}