// =================================================
//                      404
// =================================================
.not-found {
	height: calc(100vh - 150px);
	background-color: $blue;
	h2 {
		@include dinpro();
		@include fontSize(19px);
	}
	.error {
		position: relative;
		padding: 20px 0;
		@include dinpro-bold();
		@include fontSize(92px);
		@include media-breakpoint-down(sm){
			padding: 10px 0;
			@include fontSize(62px);
		}
		&:before {
			content: "";
			position: absolute;
			width: 30px;
			height: 5px;
			right: 15px;
			top: 30px;
			background-color: $white;
			@include media-breakpoint-down(sm){
				top: 15px;
			}
		}
		&:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			border: 5px solid $white;
		}
	}
	.btn {
		min-width: 150px!important;
	}
}