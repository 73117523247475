.breadcrumb {
	li {
		display: inline-block;
		padding-right: 5px;

		&:not(:first-child):before {
			content: "\203A";
			display: inline-block;
			position: relative;
			padding: 0 5px;
		}
	}
	a, span { }
}

.breadcrumd-link {
	color: $white;
	@include nimbus(700);
	@include fontSize(14px);
	@include transition(all 0.3s ease-in-out);
	&:before {
		content: "\e900";
		font-family: "icomoon";
		margin-right: 5px;
		@include fontSize(10px);
	}
	&:hover, &:focus {
		color: rgba($white, 0.8);
		@include transition(all 0.3s ease-in-out);
	}
}