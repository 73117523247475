// =================================================
//                    NAVIGATION
// =================================================
.navbar {
	@include media-breakpoint-down(lg){
		position: relative;
		.navbar-toggler {
			position: absolute;
			right: -13px;
			top: 20px;
		}
	}
	@include media-breakpoint-down(md){
		padding: 0;
		.navbar-toggler {
			top: 10px;
		}
	}
	/*@include media-breakpoint-down(sm){
		.navbar-toggler {
			margin-right: -13px;
		}
	}*/
	.menu {
		width: 100%;
		margin-left: 50px;
		@include media-breakpoint-down(lg){
			margin: 0;
			padding-top: 50px;
			padding-bottom: 25px;
		}
		@include media-breakpoint-down(sm) {
			margin-top: 30px;
			text-align: left;
			padding-top: 15px;
		}
		li {
			display: inline-block;
			margin: 0 10px;
			padding: 10px 0;
			background: linear-gradient(to bottom, transparent 98%, $blue 0) center center/0% 75% no-repeat;
			@include transition(all .3s ease-in-out);
			@include media-breakpoint-down(lg){
				display: table;
				padding: 15px 0;
				margin: 10px 0;
				background: 0;
				&:hover, &.current-menu-item {
					background: linear-gradient(to bottom, transparent 98%, $blue 0) center center/0% 75% no-repeat;
					background-size: 100% 100%;
				}
				a {
					@include fontSize(22px);
				}
			}
			&:hover, &.current-menu-item, &:focus {
				background-size: 100% 100%;
				@include transition(all .3s ease-in-out);
			}
			a {
				color: $blue;
				display: block;
				@include dinpro-medium();
				@include fontSize(15px);
				@include media-breakpoint-down(lg){
					@include fontSize(22px);
				}
				&:hover, &:focus {
					color: $blue!important;
					text-decoration: none;
				}
			}
		}
	}
	.list-social {
		border-top: 1px solid $grey-light;
		a {
			&:before {
				color: $blue;
			}
			@include fontSize(20px);
		}
	}
	.cta {
		@include media-breakpoint-down(lg){
			position: absolute;
			top: 13px;
			left: calc(50% - 85px);
		}
		@include media-breakpoint-down(md){
			top: 5px;
		}
		@include media-breakpoint-down(sm){
			display: none;
		}
	}
	.lang {
		margin: 0 20px 0 15px;
		@include dinpro-bold();
		@include fontSize(12px);
		@include media-breakpoint-down(lg){
			position: absolute;
			right: 135px;
			margin: 0 15px;
			top: 29px;
		}
		@include media-breakpoint-down(md){
			top: 19px;
		}
		@include media-breakpoint-down(sm){
			right: 100px;
		}
		a{
			padding: 5px;
			color: $jade;
			opacity: 1;
			@include transition(all .3s ease-in-out);
			&:hover, &:focus {
				opacity: .6;
				color: $jade!important;
				text-decoration: none;
				@include transition(all .3s ease-in-out);
			}
		}
	}
	.search-container {
		@include transition(all .3s ease-in-out);
		&.active {
			@include transition(all .3s ease-in-out);
			.form-group {
				width: calc(100% - 240px);
				@include transition(width .3s ease-in-out);
				@include media-breakpoint-down(lg){
					width: calc(100% - 320px);
					top: 0;
				}
				@include media-breakpoint-down(md){
					top: -10px;
				}
				@include media-breakpoint-down(sm){
					opacity: 1;
					width: 100%;
					top: 72px;
				}
			}
			input[type="search"] {
				width: 100%;
				padding: 10px 15px;
				border: 1px solid $grey-light;
				margin: 0 0 0 auto;
				display: block;
				@include transition(width .3s ease-in-out);
			}
			.icon-search {
				&:before {
					content: "\e906";
				}
			}
		}
		.form-group {
			position: absolute;
			width: 0%;
			text-align: right;
			z-index: 9999;
			right: 50px;
			padding: 15px;
			background-color: $white;
			@include transition(width .3s ease-in-out);
			@include media-breakpoint-down(lg){
				right: 120px;
				top: 10px;
			}
			@include media-breakpoint-down(md){
				top: 0;
			}
			@include media-breakpoint-down(sm){
				opacity: 0;
				right: 0;
				top: 72px;
			}
		}
		input[type="search"] {
			width: 0;
			padding: 0;
			border: 0;
			border-left: 1px solid $grey-light;
			@include transition(width .3s ease-in-out);
			@include media-breakpoint-down(sm){
				padding: 5px 15px!important;
				@include fontSize(16px);
			}
		}
		.icon-search {
			color: $jade;
			padding: 5px;
			opacity: 1;
			@include transition(all .3s ease-in-out);
			@include media-breakpoint-down(lg){
				position: absolute;
				top: 23px;
				right: 80px;
			}
			@include media-breakpoint-down(md){
				top: 15px;
			}
			@include media-breakpoint-down(sm){
				right: 50px;
			}
			&:hover, &:focus {
				opacity: .6;
				color: $jade!important;
				text-decoration: none;
				@include transition(all .3s ease-in-out);
			}
		}
	}
	.hamburger {
		padding: 0;
		&.is-active {
			.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
				//color: $white;
				//background-color: $white;
			}
		}
		.hamburger-box {
			width: 30px;
		}
		.hamburger-inner {
			@include media-breakpoint-down(lg){
				margin-top: 3px;
			}
		}
		.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
			width: 30px;
			color: $pale-blue;
			background-color: $pale-blue;
			height: 1px;
			border-radius: 0;
		}
	}
}
.context-nav {
	&.fixed {
		position: fixed;
		top: 90px;
	}

	&.bottom {
		position: absolute;
		bottom: 0;
		top: auto;
	}
	.nav-link {
		color: $grey;
		font-weight: 700;
		background-color: $white;
		padding: 15px;
		border-top: 1px solid $grey-light;
		@include fontSize(14px);
		&:last-child {
			border-bottom: 1px solid $grey-light;
		}
		&:before {
			content: "\e901";
			font-family: "icomoon";
			color: $pale-blue;
			margin-right: 10px;
		}
		&:hover, &.active {
			background-color: rgba($grey-light, .1);
		}
		&.disabled {
			cursor: not-allowed;
			&:hover {
				background-color: $white;
			}
		}
	}
}