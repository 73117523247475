.alert {
	border-radius: 1px;
	color: $grey;
	font-weight: 700;
	@include fontSize(14px);
	&.alert-info {
		border-color: $blue;
		background-color: rgba($blue, .2);
	}
	&.alert-success {
		border-color: $green;
		background-color: rgba($green, .2);
	}
	&.alert-warning {}
	&.alert-danger {
		border-color: $red;
		background-color: rgba($red, .2);
	}
}
.cookies {
	position: fixed;
	width: calc(100% - 10px);
	bottom: 0;
	left: 5px;
	z-index: 9999;
	.cookies-content {
		position: relative;
		padding: 25px 70px 15px 50px;
		background-color: rgba($grey-light, .95);
		@include fontSize(14px);
		@include transition(all .3s ease-in-out);
		a {
			color: $blue;
			font-weight: 700;
			&:hover, &:focus {
				opacity: .7;
				@include transition(all .3s ease-in-out);
			}
		}
		.ok {
			margin-left: 15px;
			min-width: 50px;
			&:hover, &:focus {
				opacity: 1;
			}
		}
		.icon-close {
			position: absolute;
			top: 0;
			right: 0;
			font-size: 11px;
			padding: 10px;
		}
	}
}
.alert-scroll {
	position: absolute;
	bottom: 40px;
	left: calc(50% - 19.5px);
	-webkit-animation-name: colorAnimation;  /* Safari 4.0 - 8.0 */
	-webkit-animation-duration: 3s;  /* Safari 4.0 - 8.0 */
	-webkit-animation-direction: alternate; /* Safari 4.0 - 8.0 */
	-webkit-animation-iteration-count: infinite; /* Safari 4.0 - 8.0 */
	animation-name: colorAnimation;
	animation-duration: 3s;
	animation-direction: alternate;
	animation-iteration-count: infinite;
	@include dinpro();
	@include fontSize(15px);
	&:before {
		content: "";
		position: absolute;
		bottom: 25px;
		left: calc(50% - .5px);
		width: 1px;
		height: 40px;
		background-color: rgba($white, .2);
	}
	&:after {
		content: "";
		position: absolute;
		bottom: 60px;
		left: calc(50% - .5px);
		width: 1px;
		height: 5px;
		-webkit-animation-name: scrollAnimation;  /* Safari 4.0 - 8.0 */
		-webkit-animation-duration: 3s;  /* Safari 4.0 - 8.0 */
		-webkit-animation-direction: alternate; /* Safari 4.0 - 8.0 */
		-webkit-animation-iteration-count: infinite; /* Safari 4.0 - 8.0 */
		animation-name: scrollAnimation;
		animation-duration: 3s;
		animation-direction: alternate;
		animation-iteration-count: infinite;
	}
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes scrollAnimation {
	0%   {
		background-color: rgba($white, .2);
		height: 5px;
		bottom: 60px;
	}
	100% {
		background-color: $white;
		height: 40px;
		bottom: 25px;
	}
}

/* Standard syntax */
@keyframes scrollAnimation {
	0%   {
		background-color: rgba($white, .2);
		height: 5px;
		bottom: 60px;
	}
	100% {
		background-color: $white;
		height: 40px;
		bottom: 25px;
	}
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes colorAnimation {
	0%   {
		color: rgba($white, .2);
	}
	100% {
		color: $white;
	}
}

/* Standard syntax */
@keyframes colorAnimation {
	0%   {
		color: rgba($white, .2);
	}
	100% {
		color: $white;
	}
}