.link-hover-none {
	background: 0!important;
	&:hover, &:focus {
		background-size: 0;
	}
	&:visited {
		background: 0!important;
	}
}
.relative {
	position: relative;
}
.hover-underline {
	display: inline;
	width: fit-content;
	background: linear-gradient(to bottom, transparent 98%, $jade 0) center center/0% 75% no-repeat;
	@include transition(all 0.3s ease-in-out);
	&.hover-white {
		background: linear-gradient(to bottom, transparent 98%, $white 0) center center/0% 75% no-repeat;
		&:hover, &:focus {
			color: $white;
		}
	}
	&.hover-blue {
		background: linear-gradient(to bottom, transparent 98%, $blue 0) center center/0% 75% no-repeat;
		&:hover, &:focus {
			color: $blue;
		}
	}
	&:hover, &:focus {
		background-size: 100% 100%;
		text-decoration: none;
		@include transition(all 0.3s ease-in-out);
	}
}

.vis-hidden {
	visibility: hidden;
	display: none;
	position: relative;
}

.none {
	display: none;
}
.border-jade {
	border-left: 5px solid $jade;
	border-right: 5px solid $jade;
}
.z-index {
	z-index: 1;
}