@font-face {
	font-family:'DINPro-Regular';
	src: url('../r2-theme/assets/fonts/dinpro/DINPro-Regular.eot');
	src: url('../r2-theme/assets/fonts/dinpro/DINPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('../r2-theme/assets/fonts/dinpro/DINPro-Regular.woff2') format('woff2'),
		url('../r2-theme/assets/fonts/dinpro/DINPro-Regular.woff') format('woff'),
		url('../r2-theme/assets/fonts/dinpro/DINPro-Regular.ttf') format('truetype'),
		url('../r2-theme/assets/fonts/dinpro/DINPro-Regular.otf') format('opentype'),
		url('../r2-theme/assets/fonts/dinpro/DINPro-Regular.svg#DINPro-Regular') format('svg');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-00FE;
}
@font-face {
	font-family:'DINPro-Medium';
	src: url('../r2-theme/assets/fonts/dinpro/DINPro-Medium.eot');
	src: url('../r2-theme/assets/fonts/dinpro/DINPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('../r2-theme/assets/fonts/dinpro/DINPro-Medium.woff2') format('woff2'),
		url('../r2-theme/assets/fonts/dinpro/DINPro-Medium.woff') format('woff'),
		url('../r2-theme/assets/fonts/dinpro/DINPro-Medium.ttf') format('truetype'),
		url('../r2-theme/assets/fonts/dinpro/DINPro-Medium.otf') format('opentype'),
		url('../r2-theme/assets/fonts/dinpro/DINPro-Medium.svg#DINPro-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-00FE;
}
@font-face {
	font-family:'DINPro-Bold';
	src: url('../r2-theme/assets/fonts/dinpro/DINPro-Bold.eot');
	src: url('../r2-theme/assets/fonts/dinpro/DINPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('../r2-theme/assets/fonts/dinpro/DINPro-Bold.woff2') format('woff2'),
		url('../r2-theme/assets/fonts/dinpro/DINPro-Bold.woff') format('woff'),
		url('../r2-theme/assets/fonts/dinpro/DINPro-Bold.ttf') format('truetype'),
		url('../r2-theme/assets/fonts/dinpro/DINPro-Bold.otf') format('opentype'),
		url('../r2-theme/assets/fonts/dinpro/DINPro-Bold.svg#DINPro-Bold') format('svg');
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-00FE;
}