.list-news {
	margin: 0;
	li {
		margin: 0;
		&:before {
			display: none;
		}
	}
	.list-news-item {
		padding: 50px 0;
		border-top: 1px solid rgba($pale-blue, .3);
		&:last-child {
			border-bottom: 1px solid rgba($pale-blue, .3);
		}
	}
}
.list-tags {
	margin: 0!important;
	li {
		display: inline-block;
		margin: 0 5px;
		a {
			@extend .link-hover-none ;
		}
	}
}
.list-social {
	li {
		display: inline-block;
		margin: 0 5px;
		padding: 10px;
		&:last-child {
			padding-right: 0;
		}
		a {
			display: block;
			color: $white;
			@include fontSize(16px);
			@extend .link-hover-none ;
		}
	}
}
.list-partners {
	width: 100%;
	overflow: hidden;
	a {
		display: inline-flex;
		align-items: center;
		max-width: 150px;
		height: 60px;
		margin: 0 20px;
		@extend .link-hover-none ;
		img {
			width: 100%;
			margin: auto;
			@include media-breakpoint-down(md){
				width: auto;
				height: 80%;
			}
		}
	}
}
.list-staff {
	 li {
		margin: 15px 0;
		a {
			@extend .link-hover-none ;
		}
	 }
}
.list-subcategory {
	a {
		@extend .link-hover-none ;
	}
	.item-subcategory {
		width: 280px;
		display: inline-block;
		margin: 0 7.5px;
	}
}
.list-products {
	li {
		&:before {
			display: none;
		}
		a {
			@extend .link-hover-none ;
		}
	}
}
.list-faq-container, .list-docs-container, .list-contacts-container, .list-news {
	margin: 0!important;
	li {
		margin: 0!important;
		&:before {
			display: none;
		}
		a {
			@extend .link-hover-none ;
		}
	}
}
.list-featured-content {
	margin: 0!important;
	li {
		margin: 30px 0 60px 0!important;
		&:before {
			display: none;
		}
		&:nth-child(even) {
			.col-6 {
				@extend .order-last ;
			}
		}
		a {
			@extend .link-hover-none ;
		}
	}
}
.list-masonry {
	.grid-item {
		width: 40%;
		//width: 42.8571428571%;
		//padding: 0 15px;
		margin: 20px 5%;
		padding: 0 15px;
		@include media-breakpoint-down(lg) {
			width: 46%;
			margin: 20px 2%;
		}
		@include media-breakpoint-down(sm) {
			width: 100%;
			margin: 20px 0;
		}
		/*&:nth-child(odd) {
			left: 0%!important;
		}
		&:nth-child(even) {
			left: 57.1428571428%!important;
		}*/
	}
}