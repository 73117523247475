h1, .h1 {
	color: $midnight-blue;
	line-height: 52px;
	@include dinpro-medium();
	@include fontSize(40px);
	@include media-breakpoint-down(md){
		line-height: 38px;
		@include fontSize(34px);
	}
}
h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	@include dinpro-medium();
}
h2, .h2 {
	line-height: 49px;
	@include fontSize(33px);
	@include media-breakpoint-down(md){
		line-height: 32px;
		@include fontSize(28px);
	}
}
h3, .h3 {
	line-height: 40px;
	@include fontSize(24px);
}
h4, .h4 {
	line-height: 36px;
	@include fontSize(18px);
}
h5, .h5 {
	line-height: 24px;
	@include fontSize(16px);
}
h6, .h6 {
	line-height: 21px;
	@include fontSize(14px);
}
ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
blockquote {
	p {
		color: $blue;
		font-style: italic;
		line-height: 20px;
		margin: 0;
		@include fontSize(20px);
		&:before, &:after {
			content: '\201C';
			position: relative;
		}
		img {
			margin: 0 0!important;
		}
	}
}

a {
	&:hover {
		text-decoration: none;
	}
	&:visited, &:focus {
		outline: none;
	}
}

hr {
	width: 100%;
	height: 1px;
	border-top: 1px solid rgba($pale-blue, .3);
}

::-moz-selection {
	background: $selection;
	text-shadow: none;
}

::selection {
	background: $selection;
	text-shadow: none;
}

code {
	font-family: 'Monaco', 'Consolas', 'Lucida Console', monospace;
	background-color: #f7f7f9;
	border: 1px solid #e1e1e8;
	width: 100%;
	font-size: 14px;
	display: block;
	padding: 15px;
	position: relative;
	&:before {
		content: 'CODE';
		display: block;
		position: absolute;
		top: 0;
		font-weight: bold;
		width: 100%;
		left: 0;
		padding: 5px 15px;
		background: $blue;
		color: white;
	}
}

pre {
	display: flex;
	margin: 0;
}