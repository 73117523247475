// =================================================
//                      HEADER
// =================================================
.main-header {
	padding: 15px;
	margin-top: 5px;
	z-index:1000;
	position:sticky!important;
	top: 0;
	margin-top: 0;
	background-color: $white;
	box-shadow: rgba(256, 256, 256, 0) 0px 0px 0px 0px,rgba(256, 256, 256, 0) 0px 0px 0px 0px;
	@include transition(all 0.6s ease-in-out);
	&.scroll{
		box-shadow: rgba(0,0,0,.1) 0px 1px 3px 0px, rgba(0,0,0,.6) 0px 1px 2px 0px;
		@include transition(all 0.6s ease-in-out);
	}

	&.header-secondary {
		/*position: absolute;
		width: 100%;
		height: 113px;
		top: -100%;
		left: 0;
		background-color: $blue;
		z-index: 2;*/

		top:-100%;
		outline:1px solid $blue;
		background-color: $blue;
		margin-top: -100px;
		@include transition(all 1.2s ease-in-out);
		&.anim {
			outline:1px solid $blue;
			top: 0;
			margin-top: 0;
			@include transition(all 1.2s ease-in-out);
		}
		.navbar {
			.menu {
				li {
					background: linear-gradient(to bottom, transparent 98%, $white 0) center center/0% 75% no-repeat;
					@include media-breakpoint-down(lg){
						background: 0;
						&:hover, &.current-menu-item {
						}
					}
					&:hover, &.current-menu-item {
						background-size: 100% 100%!important;
					}
					a {
						color: $white!important;
					}
				}
			}
			.list-social {
				a {
					&:before {
						color: $white;
					}
				}
			}
			.hamburger {
				.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
					color: $white;
					background-color: $white;
				}
			}
		}
		.search-container {
			.form-group {
				background-color: $blue;
			}
		}
		.cta {
			.btn-jade-inverted {
				color: $white;
				background-color: $blue;
			}
		}
	}
}
.logo {
	a {
		display: block;
	}
	img{
		max-width: 140px;
		width: 140px;
	}
}
.main-content {
	&.home-content {
		margin-top: -98px;
		&.anim {
			margin-top: 0;
		}
	}
}