.modal-backdrop {
	background-color: $white!important;
	&.show {
		opacity: 0.9!important;
	}
}
.accordion {
	.card {
		margin: 25px 0;
		border: 0;
		background-color: transparent;
		.card-header {
			padding: 0;
			border: 0;
			border-radius: 1px 1px 0 0;
			background-color: rgba($grey-light, .5);
			a {
				display: block;
				padding: 15px 25px;
				position: relative;
				background: 0!important;
				&:after {
					content: "\e905";
					font-family: "icomoon";
					color: $blue;
					position: absolute;
					right: 25px;
					top: calc(50% - 4px);
					@include fontSize(8px);
				}
				&.collapsed {
					&:after {
						content: "\e904";
						color: $pale-blue;
					}
					h3 {
						color: $pale-blue;
					}
				}
				&:hover {
					text-decoration: none;
					background-size: 0;
				}
				&:focus, &:visited {
					background: 0!important;
				}
				h3 {
					color: $blue;
					line-height: normal;
					@include nimbus(700);
					@include fontSize(14px);
				}
			}
		}
		.card-body {
			padding: 25px 50px 25px 25px;
			@include fontSize(16px);
			@include media-breakpoint-down(lg){
				padding: 25px;
			}
			@include media-breakpoint-down(md){
				padding: 15px;
			}

		}
	}
}
.component-featured {
	@include media-breakpoint-down(sm){
		.main-nav-tabs {
			display: none;
		}
		.tab-pane {
			margin-bottom: 60px;
			display: block;
			&:last-child(){
				margin-bottom: 0;
			}
		}
		.nav-tabs-secondary {
			.nav-item {
				.nav-link {
					pointer-events: none;
				}
			}
		}
	}
	.tabpanel-title {
		color: $midnight-blue;
	}
	.tabpanel-intro {
		color: $pale-blue;
		@include fontSize(16px);
	}
	.main-cat-image {
		height: 170px;
	}
}
.component-related-article {
	h4 {
		color: $pale-blue;
		text-transform: uppercase;
		@include nimbus();
		@include fontSize(12px);
	}
	.form-newsletter {
		background-color: $blue;
		&.bg-jade {
			background-color: $jade;
		}
	}
}
.component-contact {
	padding: 50px 0;
	border-top: 1px solid rgba($pale-blue, .3);
	@include fontSize(15px);
	.contact-links {
		color: $grey!important;
		font-weight: 400!important;
	}
	ul {
		margin: 0!important;
		li {
			margin: 0!important;
			&:before {
				display: none;
			}
		}
	}
	.item-image {
		&:after {
			content: "";
			display: block;
			padding-bottom: 100%;
		}
	}
	.second-phoneNumber {
		&:before {
			content: '/';
			margin: 0 5px;
		}
	}
}
.component-testimonial {
	blockquote {
		p {
			color: $grey;
			@include fontSize(19px);
		}
	}
}