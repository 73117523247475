.btn {
	position: relative;
	min-width: 150px;
	padding: 0.667em 1.389em;
	border: 1px solid;
	border-radius: 1px;
	border-color: black;
	color: black;
	background-color: white;
	cursor: pointer;
	@include nimbus(700);
	@include fontSize(16px);
	@include transition(all .3s ease-in-out);
	&:hover, &:focus {
		color: black;
		@include transition(all .3s ease-in-out);
	}
	&:visited {
		outline: none;
		box-shadow: none;
	}
	&.disabled {
		cursor: not-allowed;
	}
	&.btn-jade {
		color: $white;
		border-color: $jade;
		background: linear-gradient(to bottom, $white 100%, $white 0) center center/0% 50% no-repeat;
		background-color: $jade;
		&:hover, &:focus {
			color: $jade!important;
			border-color: $jade;
			background-size: 100% 100%;
			background-color: $white;
		}
	}
	&.btn-jade-inverted {
		color: $jade;
		border-color: $jade;
		background: linear-gradient(to bottom, $jade 100%, $jade 0) center center/0% 50% no-repeat;
		background-color: $white;
		&:hover, &:focus {
			color: $white!important;
			border-color: $white;
			background-size: 100% 100%;
			background-color: $jade;
		}
	}
	&.btn-pale {
		color: $white;
		border-color: $pale-blue;
		background: linear-gradient(to bottom, $white 100%, $white 0) center center/0% 50% no-repeat;
		background-color: $pale-blue;
		&:hover, &:focus {
			color: $pale-blue!important;
			border-color: $pale-blue;
			background-size: 100% 100%;
			background-color: $white;
		}
	}
	&.btn-pale-inverted {
		color: $pale-blue;
		border-color: $pale-blue;
		background: linear-gradient(to bottom, $pale-blue 100%, $pale-blue 0) center center/0% 50% no-repeat;
		background-color: $white;
		&:hover, &:focus {
			color: $white!important;
			border-color: $white;
			background-size: 100% 100%;
			background-color: $pale-blue;
		}
	}
	&.btn-blue {
		color: $white;
		border-color: $blue;
		background: linear-gradient(to bottom, $white 100%, $white 0) center center/0% 50% no-repeat;
		background-color: $blue;
		&:hover, &:focus {
			color: $blue!important;
			border-color: $blue;
			background-size: 100% 100%;
			background-color: $white;
		}
	}
	&.btn-blue-inverted {
		color: $blue;
		border-color: $blue;
		background: linear-gradient(to bottom, $blue 100%, $blue 0) center center/0% 50% no-repeat;
		background-color: $white;
		&:hover, &:focus {
			color: $white!important;
			border-color: $white;
			background-size: 100% 100%;
			background-color: $blue;
		}
	}
	&.btn-cta {
		@include dinpro-bold();
	}
	&.btn-medium {
		@include fontSize(15px);
	}
	&.btn-small {
		padding: .3em .7em;
		@include fontSize(14px);
	}
}
.btn-link-default {
	color: $jade;
	@include transition(all .3s ease-in-out);
	&:hover, &:focus {
		color: rgba($jade, .8);
		@include transition(all .3s ease-in-out);
	}
	&:focus {
		outline: none;
		box-shadow: none;
	}
}
.btn-link-secondary {
	color: $jade;
	font-weight: 700;
	box-shadow: inset 0 -11px 0 0 rgba($jade, .3);
	@include transition(all .4s ease-in-out);
	&:hover, &:focus {
		color: rgba($jade, .8);
		@include transition(all .4s ease-in-out);
	}
	&:visited {
		color: rgba($jade, .8);
		@include transition(all .4s ease-in-out);
	}
}
.tag-primary {
	position: relative;
	color: $jade;
	text-transform: uppercase;
	padding-left: 25px;
	@include dinpro-bold();
	@include fontSize(11px);
	@include transition(all .3s ease-in-out);
	&:before {
		content: "";
		background-color: $jade;
		position: absolute;
		width: 20px;
		height: 7px;
		top: calc(50% - 3px);
		left: 0px;
	}
	&:hover, &:focus {
		color: $jade;
		opacity: .8;
		text-decoration: none;
		@include transition(all .3s ease-in-out);
	}
	&.tag-white {
		color: $white;
		&:before {
			background-color: $white;
		}
	}
}
.tag-secondary {
	color: $jade;
	font-weight: 700;
	border: 1px solid $jade;
	border-radius: 2px;
	background-color: rgba($jade, .1);
	padding: 1px 6px;
	@include fontSize(12px);
	@include transition(all .3s ease-in-out);
	&:hover, &:focus {
		color: $jade;
		opacity: 0.8;
		text-decoration: none;
		@include transition(all .3s ease-in-out);
	}
	&.tag-red {
		color: $red;
		border: 1px solid $red;
		background-color: rgba($red, .1);
	}
}