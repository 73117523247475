/* ==========================================================================
	 Base styles: opinionated defaults
	 ========================================================================== */

html[lang="en-US"] {
	.home {
		.home-news {
			display: none;
		}
	}
}

body {
	// ROOT FONT - Assumes the browser default, typically `16px`, to change:
	// $font-size-base	- 1rem (16px)
	// $font-size-lg	- 1.25rem (20px)
	// $font-size-sm	- 0.875rem (14px)
	// $font-size-xs	- 0.75rem (12px)

	overflow-x: hidden !important;
	border-left: 5px solid $blue;
	border-right: 5px solid $blue;
	$font-size-base: 18px;
	font-size: $font-size-base;
	color: $grey;
	@include antialias();
	@include nimbus();
	&:before,
	&:after {
		content: "";
		position: fixed;
		background: $blue;
		left: 0;
		right: 0;
		height: 5px;
		z-index: 99999;
	}
	&:before {
		top: 0;
	}
	&:after {
		bottom: 0;
	}
	&.border-jade {
		&:before,
		&:after {
			background: $jade;
		}
	}
}

figure {
	margin: 0;
	width: 100%;
	height: 100%;
	@include backgroundCover(cover, center, no-repeat);
}
img {
	@include img-fluid();
}

$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1440px);
@include _assert-ascending($container-max-widths, "$container-max-widths");

.container {
	@include make-container();
	@include make-container-max-widths();
}

@include make-grid-columns(14);
