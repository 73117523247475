.outGrid {
	margin: 60px 0;
	position: relative;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	@include media-breakpoint-down(sm) {
		left: 0%;
		-webkit-transform: translateX(0%);
		-ms-transform: translateX(0%);
		transform: translateX(0%);
	}
	ul {
		margin: 0!important;
		li {
			&:before {
				display: none;
			}
		}
	}
	&.outGrid-secondary {
		left: 70%;
		-webkit-transform: translateX(-70%);
		-ms-transform: translateX(-70%);
		transform: translateX(-70%);
	}
}
.item-news {
	&:hover {
		.item-date {
			width: 100%;
			background-color: rgba($white, .5);
			color: $grey;
			@include transition(all 0.3s ease-in-out);
		}
	}
	.item-image {
		height: 210px;
	}
	h3 {
		line-height: 27px;
		@include fontSize(27px);
		@include media-breakpoint-down(md) {
			line-height: 26px;
			@include fontSize(24px);
		}
	}
	.item-date {
		position: absolute;
		width: 95px;
		height: 95px;
		bottom: 0;
		left: 0;
		padding: 15px;
		background-color: $white;
		color: rgba($pale-blue, 0.7);
		@include fontSize(25px);
		@include dinpro-medium();
		@include transition(all 0.3s ease-in-out);
		span {
			display: block;
			@include fontSize(15px);
			@include dinpro();
		}
	}
	.item-excerpt {
		width: 75%;
		margin-top: 10px;
		color: $pale-blue;
		@include fontSize(15px);
		@include media-breakpoint-down(lg) {
			width: 100%;
		}
	}
	&.item-news-rel {
		border-top: 1px solid rgba($pale-blue, .3);
		border-bottom: 1px solid rgba($pale-blue, .3);
		padding: 35px 0;
		@include media-breakpoint-down(sm) {
			border-bottom: 0;
		}
		.item-image {
			height: 100px;
		}
		h3 {
			@include fontSize(18px);
		}
		.item-excerpt {
			width: 100%;
		}
		.tag-primary, .item-date, .item-excerpt {
			display: none;
		}
	}
}
.item-product {
	@include transition(all 0.3s ease-in-out);
	&.disabled-link {
		a {
			pointer-events: none;
			cursor: default;
		}
		&:hover {
			.item-image {
				&:before {
					display: none;
				}
				&:after {
					width: 120px;
				}
			}
			.item-company {
				display: none;
			}
		}
	}
	&:hover {
		.item-image {
			&:before {
				opacity: 1;
				@include transition(all 0.3s ease-in-out);
			}
			&:after {
				width: calc(100% + 30px);
				@include transition(all 0.3s ease-in-out);
			}
		}
		@include transition(all 0.3s ease-in-out);
		.item-company {
			opacity: 1;
			@include transition(all 0.3s ease-in-out);
		}
	}
	.item-image {
		height: 150px;
		position: relative;
		&:before {
			content:"";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($white, .6);
			opacity: 0;
			z-index: 1;
			@include transition(all 0.3s ease-in-out);
		}
		&:after {
			content: "";
			position: absolute;
			top: 15px;
			right: -15px;
			width: 120px;
			height: 120px;
			border: 3px solid $blue;
			background-color: transparent;
			z-index: 2;
            mix-blend-mode: color-burn;
			@include transition(all 0.3s ease-in-out);
		}
	}
	.item-company {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;
		@include transition(all 0.3s ease-in-out);
		img {
			width: auto;
			height: 40%;
		}
	}
	h1, h3 {
		line-height: 30px;
		margin-top: 30px;
		@include fontSize(30px);
		@include dinpro();
		&:hover {
			color: $jade;
		}
		@include media-breakpoint-down(md) {
			line-height: 26px;
			@include fontSize(24px);
		}
	}
	.item-excerpt {
		color: $pale-blue;
		@include fontSize(15px);
	}
}
.item-subcategory {
	background-color: $white;
	border: 2px solid $grey-light;
	height: 320px;
	overflow-y: hidden;
	@include transition(all 0.3s ease-in-out);
	&:hover {
		border: 2px solid $jade;
		@include transition(all 0.3s ease-in-out);
	}
	.item-image {
		height: 135px;
	}
	.item-content {
		position: relative;
		padding: 15px;
		/*&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 20px;
			background-color: $white;
		}*/
		h3 {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			@include fontSize(19px);
		}
		.item-excerpt {
			@include multiLineEllipsis(22.5px, 4);
			@include fontSize(18px);
		}
	}
}
.item-subcategory-featured {
	color: $pale-blue;
	h3 {
		margin-bottom: 20px;
		@include dinpro();
		@include fontSize(33px);
		&:after {
			content: "─";
			margin-left: 10px;
		}
	}
	.item-excerpt {
		@include fontSize(17px);
	}
	.item-image {
		height: 150px;
		margin: 15px 0;
		position: relative;
		&:before {
			content:"";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($white, .6);
			opacity: 0;
			z-index: 1;
			@include transition(all 0.3s ease-in-out);
		}
		&:after {
			content: "";
			position: absolute;
			top: 15px;
			right: -15px;
			width: 120px;
			height: 120px;
			border: 3px solid $blue;
			background-color: transparent;
			z-index: 2;
            mix-blend-mode: color-burn;
			@include transition(all 0.3s ease-in-out);
		}
	}
	.item-content {
		@include fontSize(14px);
		ul {
			li {
				&:before {
					content: '⎯';
					margin-right: 5px;
				}
			}
		}
		a {
			color: $jade;
			font-weight: 700;
			background: linear-gradient(to bottom, transparent 62%, rgba($jade, 0.3) 0) center center/0% 75% no-repeat;
			@include transition(all .4s ease-in-out);
			&:hover, &:focus {
				background-size: 100% 100%;
				text-decoration: none;
				@include transition(all .4s ease-in-out);
			}
			&:visited {
				background-size: 80% 100%;
				@include transition(all .4s ease-in-out);
			}
		}
	}
}
.item-insurance-company {
	.item-image {
		height: 150px;
		position: relative;
		&:before {
			content:"";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($white, .6);
			z-index: 1;
		}
		&:after {
			content: "";
			position: absolute;
			top: 15px;
			right: -15px;
			width: calc(100% + 30px);
			height: 120px;
			border: 3px solid $blue;
			background-color: transparent;
			z-index: 2;
			mix-blend-mode: color-burn;
		}
	}
	.item-company {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			width: auto;
			height: 50%;
		}
	}
}
.item-featured-content {
	.item-image {
		height: 330px;
	}
}
.item-values {
	.item-image {
		background-size: contain;
		height: 85px;
	}
	h4 {
		color: $pale-blue;
		@include dinpro-bold();
		@include fontSize(20px);
	}
	.item-text {
		display: none;
		@include fontSize(15px);
	}
}
.item-staff {
	&:hover {
		.item-image {
			&:after {
				height: 100%;
				opacity: 1;
				@include transition(all 0.3s ease-in-out);
			}
		}
		.item-content {
			h4 {
				color: $jade;
			}
			span {
				color: $jade;
				@include transition(all 0.3s ease-in-out);
			}
			a {
				opacity: 1;
			}
		}
	}
	.item-image {
		height: 285px;
		position: relative;
		@include media-breakpoint-down(sm){
			height: 235px;
		}
		&:after {
			content: "";
			position: absolute;
			width: 90%;
			height: 0;
			top: -5%;
			left: 5%;
			background-color: rgba($jade, .65);
			mix-blend-mode: soft-light;
			opacity: 0;
			@include transition(all 0.3s ease-in-out);
		}
	}
	.item-content {
		padding: 10px;
		h4 {
			line-height: 20px;
			margin: 0px 0 5px 0;
			@include fontSize(20px);
		}
		span {
			display: block;
			@include dinpro-bold();
			@include fontSize(13px);
			@include transition(all 0.3s ease-in-out);
		}
		a {
			opacity: 0;
			color: $grey;
			@include fontSize(13px);
		}
	}
	&.small {
		position: relative;
		&:before {
			content:"";
			position: absolute;
			width: 90px;
			height: 90px;
			top: 15px;
			left: 15px;
			border: 3px solid $blue;
			background-color: transparent;
			z-index: -1;
		}
		h4 {
			@include fontSize(18px);
		}
		&:hover {
			.item-image {
				figure {
					transform: scale(1.3);
					@include transition(all 0.3s ease-in-out);
				}
			}
			h4 {
				color: $blue;
			}
			span {
				color: $blue;
			}
		}
		.item-image {
			width: 90px;
			height: 90px;
			overflow: hidden;
			figure {
				@include transition(all 0.3s ease-in-out);
			}
			&:after {
				display: none;
			}
		}
		.item-content {
			padding: 0;
			margin-top: 30px;
		}
	}
	&.testimonial {
		&:before {
			display: none;
		}
		.item-content {
			margin-top: 10px;
		}
	}
}
.item-doc {
	width: 90%;
	margin: 15px 0;
	margin-left: 5%;
	padding: 10px 15px;
	border-top: 1px solid rgba($pale-blue, 0.3);
	border-bottom: 1px solid rgba($pale-blue, 0.3);
	@include media-breakpoint-down(lg){
		width: 100%;
		margin-left: 0;
	}
	h4 {
		text-transform: uppercase;
		@include fontSize(16px);
	}
	.item-text {
		color: $pale-blue;
		@include fontSize(14px);
	}
}
.item-partners-secondary {
	h4 {
		color: $pale-blue;
		text-transform: uppercase;
		@include nimbus();
		@include fontSize(12px);
	}
	.list-partners {
		padding: 15px 0;
		border-top: 1px solid rgba($pale-blue, .3);
		border-bottom: 1px solid rgba($pale-blue, .3);
	}
}
.item-loader {
	.sk-cube-grid {
		width: 50px;
		height: 50px;
		margin: 100px auto;
		.sk-cube {
			width: 49%;
			height: 49%;
			background-color: $blue;
			float: left;
			-webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
					animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
			&.sk-cube1 {
				-webkit-animation-delay: 0.2s;
					animation-delay: 0.2s;
			}
			&.sk-cube2 {
				-webkit-animation-delay: 0.3s;
					animation-delay: 0.3s;
			}
			&.sk-cube3 {
				-webkit-animation-delay: 0.4s;
					animation-delay: 0.4s;
			}
			&.sk-cube4 {
				-webkit-animation-delay: 0.1s;
					animation-delay: 0.1s;
			}
			/*http://tobiasahlin.com/spinkit/*/
		}
	}
}
@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}