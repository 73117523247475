@font-face {
  font-family: 'icomoon';
  src:  url('../r2-theme/assets/fonts/icomoon/icomoon.eot?2yduph');
  src:  url('../r2-theme/assets/fonts/icomoon/icomoon.eot?2yduph#iefix') format('embedded-opentype'),
		url('../r2-theme/assets/fonts/icomoon/icomoon.ttf?2yduph') format('truetype'),
		url('../r2-theme/assets/fonts/icomoon/icomoon.woff?2yduph') format('woff'),
		url('../r2-theme/assets/fonts/icomoon/icomoon.svg?2yduph#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-checked-full:before {
  content: "\e90b";
}
.icon-arrow-left:before {
	content: "\e900";
}
.icon-arrow-right:before {
	content: "\e901";
}
.icon-check:before {
	content: "\e902";
}
.icon-checked:before {
	content: "\e903";
}
.icon-chevron-down:before {
	content: "\e904";
}
.icon-chevron-up:before {
	content: "\e905";
}
.icon-close:before {
	content: "\e906";
}
.icon-facebook:before {
	content: "\e907";
}
.icon-linkedin:before {
	content: "\e908";
}
.icon-search:before {
	content: "\e909";
}
.icon-twitter:before {
	content: "\e90a";
}
