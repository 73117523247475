// GENERAL AND PROJECT SPECIFIC MIXINS

// ===============================================
// ANTIALIAS
// ===============================================
// Font-smoothing
@mixin antialias {
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// ===============================================
// BACKGROUND-COVER
// ===============================================
// Quickly convert your background-image to whatever size, position and repeat mode you want
@mixin backgroundCover($size, $position, $repeat) {
	background-size: $size;
	background-position: $position;
	background-repeat: $repeat;
}

// ===============================================
// BACKGROUND-PARALLAX
// ===============================================
// A simple PARALLAX
@mixin backgroundParallax($url) {
	background-image: url('#{$url}');
	height: 100%;
	background-attachment: fixed;
	@include transition(all 0.5s);
	@include backgroundCover(cover, center, no-repeat);
}

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
	overflow: hidden;
	position: relative;
	line-height: $lineHeight;
	max-height: $lineHeight * $lineCount;
	padding-right: 15px;
	//text-align: justify;
	//margin-right: -1em;
	//padding-right: 2em;
	&:before {
		content: '...';
		position: absolute;
		right: 0;
		bottom: 0;
	}
	&:after {
		content: '';
		position: absolute;
		right: 0;
		width: 1em;
		height: 1em;
		margin-top: 0.2em;
		background: $bgColor;
	}
}

.block-with-text {
  @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 3, $bgColor: white);
}

// ===============================================
// FONTS SIZING
// ===============================================
// Quickly convert pixels to rem
@function calculateRem($size) {
	$remSize: $size / 16px;
	@return #{$remSize}rem;
}
@mixin fontSize($size) {
	font-size: $size; //Fallback in px
	font-size: calculateRem($size);
}

// ===============================================
// FONTS
// ===============================================

//INSERIR FONTS - EXEMPLO
// DINPRO
// Regular			-> 400
// Regular Italic	-> 400 Italic
// Bold				-> 700
// Bold Italic		-> 700 Italic
@mixin nimbus($weight:normal, $style:normal) {
	font-family: 'nimbus-sans';
	font-weight: $weight;
	font-style: $style;
}

//INSERIR FONTS - EXEMPLO
// DINPRO
// Regular			-> 400
@mixin dinpro($weight:normal, $style:normal) {
	font-family: 'DINPro-Regular';
	font-weight: $weight;
	font-style: $style;
}
// DINPRO MEDIUM
// Medium			-> 500
@mixin dinpro-medium($weight:normal, $style:normal) {
	font-family: 'DINPro-Medium';
	font-weight: $weight;
	font-style: $style;
}
// DINPRO BOLD
// Bold			-> 700
@mixin dinpro-bold($weight:normal, $style:normal) {
	font-family: 'DINPro-Bold';
	font-weight: $weight;
	font-style: $style;
}
